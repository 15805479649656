import React from 'react';
import PropTypes from 'prop-types';
import styles from './DoubleColumn.module.scss';

const DoubleColumn = ({ colLeft, colRight }) => (
  <div className={styles.doubleCol}>
    <div>{colLeft}</div>
    <div>{colRight}</div>
  </div>
);

DoubleColumn.propTypes = {
  colLeft: PropTypes.node.isRequired,
  colRight: PropTypes.node.isRequired,
};

export default DoubleColumn;
