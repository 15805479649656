import React from 'react';
import PropTypes from 'prop-types';
import testimonialType from '../../proptypes/testimonialType';
import styles from './Testimonial.module.scss';


const Testimonial = ({ testimonial, className }) => (
  <div className={[styles.testimonial, className].join(' ')}>
    <q className={styles.quote}>{testimonial.quote}</q>
    <div className={styles.name}>{testimonial.name}</div>
  </div>
);

Testimonial.propTypes = {
  testimonial: PropTypes.shape(testimonialType).isRequired,
};

export default Testimonial;
