import React from 'react';
import Section from '../Section/Section';
import SectionLabel from '../SectionLabel/SectionLabel';
import SectionContent from '../SectionContent/SectionContent';
import ContactInfo from '../ContactInfo/ContactInfo';
import Contact from '../../forms/Contact';
import styles from './ContactSection.module.scss';

const ContactSection = () => (
  <Section id="contact" theme="dark-bg">
    <SectionContent className={styles.row}>
      <div className={styles.rowItem}>
        <SectionLabel label="Contact" />
        <h3 styles={styles.h2}>Get in Touch Today</h3>
        <ContactInfo />
      </div>
      <div className={styles.rowItem}>
        <Contact />
      </div>
    </SectionContent>
  </Section>
);

export default ContactSection;
