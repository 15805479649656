import React from 'react';
import PropTypes from 'prop-types';
import testimonialType from '../../proptypes/testimonialType';
import Carousel from '../Carousel/Carousel';
import Testimonial from '../Testimonial/Testimonial';


const TestimonialCarousel = ({ testimonials }) => (
  <Carousel navigation>
    {testimonials.map(testimonial => (
      <Testimonial key={testimonial.key} testimonial={testimonial} />
    ))}
  </Carousel>
);

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape(testimonialType),
  ).isRequired,
};

export default TestimonialCarousel;
