import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionContentForButton.module.scss';

const SectionContentForButton = ({ centered, className, children }) => (
  <div
    className={[
      styles.content,
      centered && styles.centered,
      className,
    ].join(' ')}
  >
    {children}
  </div>
);

SectionContentForButton.propTypes = {
  centered: PropTypes.bool,
};

SectionContentForButton.defaultProps = {
  centered: false,
};

export default SectionContentForButton;
