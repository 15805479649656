import React from 'react';
import styles from './Tabs.module.scss';

const Tabs = ({ className, children }) => (
  <div className={[className, styles.wrapper].join(' ')}>
    <div className={styles.tabs}>
      {children}
    </div>
  </div>
);

export default Tabs;
