import React from 'react';
import styles from './ContactInfo.module.scss';

import emailIcon from '../../images/icons/Timberland-Mail-Circle.svg';
import phoneIcon from '../../images/icons/Timberland-Phone-Circle.svg';
import locationIcon from '../../images/icons/Timberland-Pin-Circle.svg';

const ContactInfo = () => (
  <address className={['vcard', styles.wrapper].join(' ')}>
    <ul>
      <li>
        <div className={styles.icon}>
          <img src={phoneIcon} alt="Phone contact" />
        </div>
        <div className={styles.content}>
          <a className={['tel', styles.tel].join(' ')} href="tel:01566774200">01566 774200</a>
        </div>
      </li>
      <li>
        <div className={styles.icon}>
          <img src={emailIcon} alt="Email contact" />
        </div>
        <div className={styles.content}>
          <a className={['email', styles.email].join(' ')} href="mailto:sales@timberlandwindows.com">sales@timberlandwindows.com</a>
        </div>
      </li>
      <li>
        <div className={styles.icon}>
          <img src={locationIcon} alt="Location contact" />
        </div>
        <div className={['adr', styles.adr, styles.content].join(' ')}>
          <div className="street-address">
            Unit 13a Pennygillam Way,
            <br />
            Pennygillam Ind. Estate,
          </div>
          <span className="locality">Launceston,</span>
          &nbsp;
          <span className="region">Cornwall,</span>
          <div className="country-name">United Kingdom,</div>
          <div className="postal-code">PL15 7ED</div>
        </div>
      </li>
    </ul>
  </address>
);

export default ContactInfo;
