import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import '../fonts/NeutraText-Bold.otf';
import '../util/fontawesome';
import heroHook from '../hooks/heroHook';
import servicesHook from '../hooks/servicesHook';
import uspsHook from '../hooks/uspsHook';
import testimonialCarouselHook from '../hooks/testimonialCarouselHook';
import Layout from '../components/layout';
import HeroSection from '../components/HeroSection/HeroSection';
import Section from '../components/Section/Section';
import SectionContent from '../components/SectionContent/SectionContent';
import SectionContentForButton from '../components/SectionContentForButton/SectionContentForButton';
import About from '../components/About/About';
import SectionLabel from '../components/SectionLabel/SectionLabel';
import TabbedView from '../components/TabbedView/TabbedView';
import TabView from '../components/TabView/TabView';
import FeatureList from '../components/FeatureList/FeatureList';
import Gallery from '../components/Gallery/Gallery';
import Button from '../components/Button/Button';
import FeaturedPros from '../components/FeaturedPros/FeaturedPros';
import TestimonialCarousel from '../components/TestimonialCarousel/TestimonialCarousel';
import ContactSection from '../components/ContactSection/ContactSection';
import BWCLink from '../components/BWCLink/BWCLink';
import SEO from '../components/seo';
import StatCounter from 'statcounter';

import bgImg from '../images/bi-folds_&_side_screens.jpg';

import signature from '../images/Timberland-Sig-01.svg';

const IndexPage = () => {
  const hero = heroHook();
  const services = servicesHook();
  const usps = uspsHook();
  const testimonials = testimonialCarouselHook();

  return (
    <Layout transparentHeader>
      <SEO title="Home" />
      <HeroSection
        heading={hero.heading}
        subHeading={hero.subHeading}
        imageFluid={hero.background.fluid}
      />
      <Section theme="dark-bg">
        <SectionContent>
          <About imgUrl={bgImg} imgAlt="About image">
            <Fragment>
              <SectionLabel label="About Timberland" />
              <h2>
                Windows, Doors, Conservatories
                <br />
                and Bespoke Joinery
              </h2>
              <p>
                Timberland Windows was
                &nbsp;
                <strong>set up in 2008</strong>
                &nbsp;to produce windows made of wood, combining
                &nbsp;
                <strong>traditional elegance, craftsmanship and good looks</strong>
                &nbsp;with
                &nbsp;
                <strong>modern innovative features</strong>
                &nbsp;, such as energy efficiency, ease of use, low maintenance and durability.
                &nbsp;
                <strong>Modern eco-friendly manufacturing methods</strong>
                &nbsp;are used together with
                &nbsp;
                <strong>sustainably sourced and engineered timbers</strong>
                &nbsp;.
              </p>
              <img src={signature} alt="timberland signature" style={{ width: 250 }} />
            </Fragment>
          </About>
        </SectionContent>
      </Section>

      <Section>
        <TabbedView>
          {services.map(service => (
            <TabView key={service.key} label={service.name}>
              <SectionContent centered>
                <h2>{service.title}</h2>
                <FeatureList
                  features={service.usps}
                />
              </SectionContent>
              {service.gallery
                && <Gallery galleryAssets={service.gallery} />
              }
              <SectionContentForButton centered>
                <Button onClick={() => navigate(`/service/${service.slug}`)} label="Find out more" cta />
              </SectionContentForButton>
            </TabView>
          ))}
        </TabbedView>
      </Section>

      <Section theme="dark">
        <FeaturedPros
          pros={usps}
        />
      </Section>

      <Section>
        <SectionContent centered>
          <SectionLabel label="What our customers say" />
          <TestimonialCarousel
            testimonials={testimonials}
          />
          <br />
          <Button label="See more testimonials" onClick={() => navigate('/testimonials')} />
          <br />
          <br />
          <BWCLink />
        </SectionContent>
      </Section>

      <ContactSection theme="dark-bg" />
      <StatCounter sc_project={12980163} sc_security="0dbe8d14" />
    </Layout>
  );
};

export default IndexPage;
