import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TabbedView.module.scss';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tab/Tab';
import TabSelect from '../TabSelect/TabSelect';


export default class TabbedView extends React.Component {
  constructor(props) {
    super(props);
    const { selected } = this.props;
    this.handleTabTouch = this.handleTabTouch.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.state = { selected };
  }

  handleTabTouch(index) {
    this.setState({
      selected: index,
    });
  }

  renderTabs() {
    const { children } = this.props;
    const { selected } = this.state;
    const childMap = new Map();
    const tabs = (child, index) => (
      <Tab
        key={index}
        onClick={() => this.handleTabTouch(index)}
        icon={child.props.icon}
        active={selected === index}
        label={child.props.label}
      />
    );

    children.map((child, index) => childMap.set(index, child.props.label));

    return (
      <Tabs>
        {children.map(tabs.bind(this))}
      </Tabs>
    );
  }

  renderContent() {
    const { children } = this.props;
    const { selected } = this.state;
    return (
      <div>
        {children[selected]}
      </div>
    );
  }

  render() {
    const wrapperStyles = [
      styles.tabbedView,
    ].join(' ');
    return (
      <div className={wrapperStyles}>
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    );
  }
}


TabbedView.propTypes = {
  selected: PropTypes.number,
};

TabbedView.defaultProps = {
  selected: 0,
};
