import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tab.module.scss';


const Tab = ({ label, active, onClick }) => (
  <div
    className={[styles.tab, active && styles.active].join(' ')}
    onClick={onClick}
    onKeyUp={(e) => {
      if (e.keyCode === 13) {
        e.target.click();
      }
    }}
    role="button"
    tabIndex={0}
  >
    {label}
  </div>
);

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

Tab.defaultProps = {
  active: false,
};

export default Tab;
