import React from 'react';
import PropTypes from 'prop-types';
import styles from './RoundImage.module.scss';

const RoundImage = ({ className, url, alt }) => (
  <div className={[styles.wrapper, className].join(' ')}>
    <div className={styles.squareWrapper}>
      <img
        src={url}
        className={styles.roundImage}
        alt={alt}
      />
    </div>
  </div>
);


RoundImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};


export default RoundImage;
