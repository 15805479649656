import React from 'react';
import { navigate } from 'gatsby';
import bwcLogo from '../../images/bwc-logo.jpg';
import tmgLogo from '../../images/TrustMarkGovernmentEndorsedLogo.jpg';
import cpaLogo from '../../images/ConsumerProtectionAssociation.gif';
import styles from './BWCLink.module.scss';

const BWCLink = () => (
  <div
    className={styles.flexcontainer}
    role="button"
    tabIndex="0"
    onClick={() => navigate('/buy-with-confidence')}
    onKeyUp={(e) => {
      if (e.keyCode === 13) {
        navigate('/buy-with-confidence');
      }
    }}
  >
    <div
      className={styles.wrapper}
    >
      <img
        className={styles.logo}
        src={bwcLogo}
        alt="buy with confidence"
      />
    </div>
    <div
      className={styles.wrapper}
    >
      <img
        className={styles.logo}
        src={tmgLogo}
        alt="trustmark government endorsed quality"
      />
    </div>
    <div
      className={styles.wrapper}
    >
      <img
        className={styles.logo}
        src={cpaLogo}
        alt="consumer protection association"
      />
    </div>
  </div>
);

export default BWCLink;
