import React from 'react';
import PropTypes from 'prop-types';
import DoubleColumn from '../DoubleColumn/DoubleColumn';
import RoundImage from '../RoundImage/RoundImage';
import styles from './About.module.scss';


const About = ({ imgUrl, imgAlt, children }) => (
  <DoubleColumn
    colLeft={(
      <RoundImage
        className={styles.roundImage}
        url={imgUrl}
        alt={imgAlt}
      />
    )}
    colRight={children}
  />
);


About.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

export default About;
