import PropTypes from 'prop-types';
import React from 'react';
import styles from './TabSelect.module.scss';

const getLabelForValue = (items, value) => {
  const defaultLabel = 'Select...';

  if (items instanceof Map) {
    return items.get(value) || defaultLabel;
  }

  if (items instanceof Array) {
    return items.filter(([key]) => value === key).pop() || defaultLabel;
  }

  return items[value] || defaultLabel;
};

export default class TabSelect extends React.Component {

  render() {
    // Items can be a Map of key:label pairs, or an array of key value pairs.
    // The Map order is the list order.
    const { className, value, items, onChange, disabled } = this.props;

    const wrapperClasses = [
      className,
      styles.wrapper,
    ].join(' ');

    const options = (items instanceof Map) ? Array.from(items.entries()) : Object.entries(items);

    return (
      <div className={wrapperClasses}>
        <div className={styles.selectButton}>
          <div className={styles.selectButtonLabel}>{getLabelForValue(items, value)} ^</div>
        </div>
        <div className={styles.selectWrapper}>
          <select disabled={disabled} name={name} value={value} onChange={onChange}>
            {options.map(([key, description]) =>
              <option key={key} value={key}>{description}</option>)
            }
          </select>
        </div>
      </div>
    );
  }
}

TabSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.instanceOf(Map),
  ]).isRequired,
  disabled: PropTypes.bool,
};

TabSelect.defaultProps = {
  disabled: false,
};
